function toStr(timestamp: number) {
	// Округляем до миллисекунд (оставляем 3 знака после точки)
	const t = Math.floor(timestamp * 1000) / 1000;

	// Преобразуем в строку
	const timeStr = t.toString();

	// Оставляем только цифры
	let string = timeStr.replace(/[^0-9]/g, '');

	// Дополняем нулями справа до 13 символов
	if (string.length < 13) {
		string = string.padEnd(13, '0');
	}

	return string;
}

function randomize(string: string) {
	const s = [];
	for (let i = 0; i < string.length; i++) {
		s.push(Math.floor(Math.random() * 10).toString());
		s.push(string[i]);
	}
	return s.join('');
}

function toHex(string: string) {
	function intoHex(s: string) {
		const hex = parseInt(s, 10).toString(16);
		return hex.length === 2 ? hex : '0' + hex;
	}

	const t = [];

	while (string.length) {
		const r = string.length >= 2 ? string.slice(0, 2) : string;
		string = string.slice(2);
		t.push(intoHex(r));
	}

	return t.join('');
}

export function prepareTimestamp(timestamp: number) {
	const timeStr = toStr(timestamp);
	const shaked = randomize(timeStr);
	const hexed = toHex(shaked);

	return hexed;
}
