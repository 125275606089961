import camelCaseKeys from 'camelcase-keys';
import { AxiosResponse, AxiosError } from 'axios';

import { parseErrorMessages } from '../helpers';
import { apiSentryException, RequestError } from '..';

export function preprocessSuccessResponse(response: AxiosResponse) {
	return {
		...response,
		data: camelCaseKeys(response.data, { deep: true }),
	};
}

export function preprocessErrorResponse(error: AxiosError) {
	const name = error?.config?.url ?? '';
	const status = error?.response?.status;
	const requestError = new RequestError(name, error.request, error.response, status);

	apiSentryException(requestError);
	parseErrorMessages(error);

	return Promise.reject(error);
}
