//saga
import { put, takeEvery, call, all, select } from 'redux-saga/effects';

//api
import {
	driverGetCertificates,
	driverGetInsurances,
	driverGetTransportList,
	driverCreateTransport,
	driverUpdateTransport,
	apiSentryException,
} from 'app/api';

//actions
import { DriverActions } from 'app/actions';

//selectors
import { user as userSelector } from 'app/selectors';

//helpers
import { arrayToMap } from 'app/utils';

//types
import { DriverCar, Transport } from 'app/models';

type Action<T> = {
	type: string;
	payload: T;
};

type CertificateResponse = {
	documentFlow: string; // truck' || 'trailer'
};

//sagas
function* getCarCertificates(action: any): Generator<any, void, any> {
	const { uuid } = action.payload;

	yield put(DriverActions.setCertificatesLoading(uuid));
	const res = yield call(driverGetCertificates, uuid);

	// truck before the trailer certificates
	const sortedCertificates = res.sort(
		({ documentFlow: a = '' }: CertificateResponse, { documentFlow: b = '' }: CertificateResponse) =>
			a.length - b.length,
	);

	yield put(DriverActions.setCertificatesByCar({ uuid, data: arrayToMap(sortedCertificates) }));
}

function* getCarInsurances(action: any): Generator<any, void, any> {
	const { uuid } = action.payload;

	yield put(DriverActions.setInsurancesLoading(uuid));
	const res = yield call(driverGetInsurances, uuid);

	yield put(DriverActions.setInsurancesByCar({ uuid, data: arrayToMap(res) }));
}

function* getDriverTransportList(action: any): Generator<any, void, any> {
	const { contact } = action.payload;

	yield put(DriverActions.setDriverLoadingState(true));

	const result = yield call(driverGetTransportList);

	const cars: DriverCar[] = result.map((transport: any) => {
		const certificates: DriverCar['certificates'] = {};
		const insurances: DriverCar['insurances'] = {};

		const car: DriverCar = {
			uuid: transport.uuid,
			certificates,
			insurances,
			transport,
			contact,
		};

		return car;
	});

	yield all(cars.map((car) => put(DriverActions.addDriverTransport(car))));
	yield put(DriverActions.setDriverLoadingState(false));
}

function* updateDriverTransport(action: Action<DriverActions.Payload.Docs<Transport>>): Generator<any, void, any> {
	const { transportId, data } = action.payload;

	try {
		let updatedTransportId = '';

		if (transportId === DriverActions.NEW_TRANSPORT_ID) {
			const transport = yield call(driverCreateTransport, {
				transportType: { uuid: data?.transportType?.uuid } as any,
			});
			updatedTransportId = transport.uuid;
			const contact = yield select(userSelector.getBasicProfile);
			const transportData = {
				uuid: transport.uuid,
				transport,
				contact,
				certificates: {},
				insurances: {},
			};
			yield put(DriverActions.setOpenedTransport(updatedTransportId));
			yield put(DriverActions.addDriverTransport(transportData));
			yield put(DriverActions.deleteTransport(DriverActions.NEW_TRANSPORT_ID));
		}

		const updatedTransport = yield call(
			driverUpdateTransport,
			transportId === DriverActions.NEW_TRANSPORT_ID ? updatedTransportId : transportId,
			data,
		);

		yield put(DriverActions.setUpdatedDriverTransport(updatedTransport));

		yield put(DriverActions.getCarInfoCertificates(transportId));
	} catch (error) {
		yield call<any>(apiSentryException, error); // TODO: check type checking
	}
}

export function* watchGetCarCertificates() {
	yield takeEvery(DriverActions.Action.GET_CAR_CERTIFICATES, getCarCertificates);
	yield takeEvery(DriverActions.Action.GET_CAR_INSURANCES, getCarInsurances);
	yield takeEvery(DriverActions.Action.GET_DRIVER_TRANSPORT, getDriverTransportList);
	yield takeEvery(DriverActions.Action.TRANSPORT_UPDATE, updateDriverTransport);
}
