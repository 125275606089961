import { takeEvery, call, put, all, select } from 'redux-saga/effects';
import { Action } from 'redux-actions';
import { toast } from 'react-toastify';

//types
import { BidActions, CommonActions } from 'app/actions';
import { Bid, SidebarType } from 'app/models';

//helpers
import * as api from '../api';

function* deleteBid(action: Action<string>) {
	if (action.payload) {
		try {
			yield api.bidDelete(action.payload);
			yield call(toast.info, 'Заявка удалена', { position: toast.POSITION.BOTTOM_CENTER });

			yield all([
				//@ts-ignore
				put(BidActions.getBids({ type: Bid.BidType.All })),
				//@ts-ignore
				put(BidActions.getBids({ type: Bid.BidType.My })),
				//@ts-ignore
				put(CommonActions.toggleSidebar({ type: SidebarType.OrderEdit, value: false })),
			]);
		} catch (e) {
			yield call<any>(api.apiSentryException, e); // TODO: check type checking
		}
	}
}

const bidActionMapper = {
	[Bid.BidType.All]: BidActions.setAllBids,
	[Bid.BidType.Archive]: BidActions.setArchiveBids,
	[Bid.BidType.My]: BidActions.setMyBids,
};

// const removeEmptyParams = (params: any) => params.reduce((acc, el))

export const serializeParams = (params: any) => {
	return Object.keys(params)
		.map((key) => {
			if (key === 'type' && params[key] === 'all') {
				return '';
			}
			return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
		})
		.join('&');
};

export const serializeParams1 = (params: any) => {
	return Object.keys(params)
		.reduce((acc: any, key: any) => {
			if ((key === 'type' && params[key] === 'all') || !params[key]) {
				return acc;
			}
			return [...acc, `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`];
		}, [])
		.join('&');
};

function* getBids(action: Action<any>): Generator<any, void, any> {
	const { filter, bounds } = yield select((state) => state.transportOnMap);
	// const { filters } = yield select(state => state.bid);

	yield put(BidActions.setLoading({ type: action.payload.params.type, value: true }));

	if (!action.payload.loadMore) {
		yield put(
			//@ts-ignore
			bidActionMapper[action.payload.params.type]({ list: [], cursor: { previous: null, next: null } }),
		);
	}

	const bbox =
		filter && bounds && typeof bounds === 'string' && !action.payload.skipBox ? `?in_bbox=${bounds}&size=200` : '?';

	const preparedParams = serializeParams1(
		api.prepareParams({ ...action.payload.params, cursor: action?.payload?.cursor }),
	);

	const newBids = yield api.v1.get(`/bid${bbox}${preparedParams}`);

	const { data, meta } = newBids?.data || {};
	const list = { list: data, cursor: meta?.links };

	//@ts-ignore
	yield put(bidActionMapper[action.payload.params.type]({ ...list, loadMore: action.payload.loadMore }));

	//@ts-ignore
	yield put(BidActions.setLoading({ type: action.payload.params.type, value: false }));
}

export function* watchBid() {
	yield takeEvery(BidActions.Action.DELETE_BID, deleteBid);
	yield takeEvery(BidActions.Action.GET_BIDS_NEW, getBids);
}
