import axios from 'axios';
import camelCaseKeys from 'camelcase-keys';
import snakeCaseKeys from 'snakecase-keys';

import { getEnvUrl, IS_DEVELOPMENT_ENV } from 'app/config';

import { preprocessRequest, preprocessSuccessResponse, preprocessErrorResponse } from './interceptors';

export * from './executor';
export * from './suggests';
export * from './prices';
export * from './driver';
export * from './emptyCar';
export * from './maps';
export * from './user';
export * from './rate';
export * from './bid';

export const apiSentryException = (error: RequestError) => {
	if (process.env.NODE_ENV === 'development') {
		console.log('[apiSentryException]: ', { error });
	}
};

export class RequestError extends Error {
	public statusCode: number;
	public sentryId?: string;
	public name: string;
	public request: any;
	public data: any;

	constructor(name: string, request?: any, data?: any, statusCode: number = 500) {
		super(`Rejected Response "${name}" with ${statusCode}`);

		this.statusCode = statusCode;
		this.request = request;
		this.data = data;
		this.name = name;
	}
}

export interface RequestParams {
	[index: string]: any;
}

export interface ResponseParams {
	[index: string]: any;
}

export function prepareParams(source: RequestParams): RequestParams {
	return snakeCaseKeys<RequestParams>(source);
}

const hasValue = (el: unknown) => {
	if (Array.isArray(el)) return !!el.length;
	else return !!el;
};

export const normalizeParams = <T extends Record<string, unknown>>(obj: T): Partial<T> => {
	return Object.keys(obj).reduce<Partial<T>>((acc, key) => {
		return hasValue(obj[key]) ? { ...acc, [key]: obj[key] } : { ...acc };
	}, {});
};

export function prepareResponse<T extends { [key: string]: any }>(source: ResponseParams): T {
	return camelCaseKeys<T>(source as T, { deep: true });
}

const API_V1_HOST = IS_DEVELOPMENT_ENV
	? `https://${getEnvUrl()}vezzer.no/api/v1`
	: window.location.origin === 'https://app.dev.vezzer.no'
	? 'https://dev.vezzer.no/api/v1'
	: 'https://vezzer.no/api/v1';

const API_V2_HOST = IS_DEVELOPMENT_ENV
	? `https://${getEnvUrl()}vezzer.no/api/v2`
	: window.location.origin === 'https://app.dev.vezzer.no'
	? 'https://dev.vezzer.no/api/v2'
	: 'https://vezzer.no/api/v2';

export const v1 = axios.create({ baseURL: API_V1_HOST, withCredentials: true });
v1.interceptors.request.use(preprocessRequest);
v1.interceptors.response.use(preprocessSuccessResponse, preprocessErrorResponse);

export const v2 = axios.create({ baseURL: API_V2_HOST });
v2.interceptors.request.use(preprocessRequest);
v2.interceptors.response.use(preprocessSuccessResponse, preprocessErrorResponse);
